import { ComponentType } from "@angular/cdk/portal";
import { Injectable, inject } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ResponsiveModalContainerService } from "@e-tenant-hub/client-platform/shared/ui/responsive/modal-container";

@Injectable({
	providedIn: "root",
})
export class WebModalContainerService<T> extends ResponsiveModalContainerService<T> {
	private readonly dialog = inject(MatDialog);

	private dialogRef?: MatDialogRef<T>;
	private data: unknown;

	constructor() {
		super();
	}

	override openDialog(component: ComponentType<T>, data?: unknown, config?: any): void {
		this.dialogQueue.push({ componentType: component, data: data, config: config });
		this.openNextDialog();
	}

	private openNextDialog() {
		if (this.dialogRef) return;

		const modalEntity = this.dialogQueue.shift();

		if (!modalEntity || !modalEntity.componentType) return;

		this.setDialogData(modalEntity.data);

		this.dialogRef = this.dialog.open(modalEntity.componentType, {
			width: modalEntity.config?.width ?? undefined,
			minWidth: "33vw",
			maxHeight: "85vh",
			maxWidth: modalEntity.config?.maxWidth ?? undefined,
			disableClose: true,
			data: modalEntity.data,
			autoFocus: false,
		});
	}

	override closeDialog(): void {
		this.dialogRef?.close();
		this.dialogRef = undefined;
		this.data = undefined;
		setTimeout(() => this.openNextDialog(), 1000);
	}

	override getDialogData(): unknown {
		return this.data;
	}

	override reloadData(data: unknown): void {
		this.setDialogData(data);
	}

	private setDialogData(data: unknown) {
		this.data = data;
	}
}
