import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { LOCALE_ID, NgModule, isDevMode } from "@angular/core";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { MobileModalContainerService } from "@e-tenant-hub/client-platform/shared/ui/mobile/modal-container";
import {
	PRECISE_DATE_FORMATS,
	PreciseDateAdapter,
} from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/date-picker-inputs";
import { ResponsiveModalContainerService } from "@e-tenant-hub/client-platform/shared/ui/responsive/modal-container";
import { WebModalContainerService } from "@e-tenant-hub/client-platform/shared/ui/web/modal-container";
import { FooterModule } from "@e-tenant-hub/client-platform/shell/ui/footer";
import { HeaderModule } from "@e-tenant-hub/client-platform/shell/ui/header";
import { NavBarModule } from "@e-tenant-hub/client-platform/shell/ui/nav-bar";
import { AuthModule } from "@e-tenant-hub/shared/auth";
import { RentalsModule } from "@e-tenant-hub/shared/rentals";
import { JsonDateInterceptor, ScreenSizeService, UtilsModule } from "@e-tenant-hub/shared/utils";
import { NgxWebstorageModule } from "ngx-webstorage";
import { AppComponent } from "./app.component";
import { appRoutes } from "./app.routes";
import { SigninCallbackComponent } from "./components/signin-callback/signin-callback.component";
import { SignoutCallbackComponent } from "./components/signout-callback/signout-callback.component";

import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import { ServiceWorkerModule } from "@angular/service-worker";
import { FullPageLayoutModule } from "@e-tenant-hub/client-platform/shell/ui/layouts/full-page-layout";
registerLocaleData(localeFr);

const initializeModaleServiceFn = (
	screenSizeService: ScreenSizeService
): WebModalContainerService<unknown> | MobileModalContainerService<unknown> =>
	screenSizeService.isSmallScreen() ? new MobileModalContainerService() : new WebModalContainerService();

@NgModule({
	declarations: [AppComponent, SigninCallbackComponent, SignoutCallbackComponent],
	imports: [
		RouterModule.forRoot(appRoutes),
		AuthModule,
		BrowserModule,
		NgxWebstorageModule.forRoot(),
		HttpClientModule,
		BrowserAnimationsModule,
		MatSidenavModule,
		FooterModule,
		HeaderModule,
		NavBarModule,
		RentalsModule,
		UtilsModule,
		FullPageLayoutModule,
		ServiceWorkerModule.register("ngsw-worker.js", {
			enabled: !isDevMode(),
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: "registerWhenStable:30000",
		}),
	],
	providers: [
		{ provide: LOCALE_ID, useValue: "fr-FR" },
		{
			provide: ResponsiveModalContainerService,
			useFactory: initializeModaleServiceFn,
			deps: [ScreenSizeService],
		},
		{ provide: DateAdapter, useClass: PreciseDateAdapter },
		{ provide: MAT_DATE_FORMATS, useValue: PRECISE_DATE_FORMATS },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JsonDateInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
