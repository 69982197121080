import { ComponentType } from "@angular/cdk/portal";
import { Injectable, inject } from "@angular/core";
import { MatBottomSheet, MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { ResponsiveModalContainerService } from "@e-tenant-hub/client-platform/shared/ui/responsive/modal-container";

@Injectable({
	providedIn: "root",
})
export class MobileModalContainerService<T> extends ResponsiveModalContainerService<T> {
	private readonly bottomSheet = inject(MatBottomSheet);

	private bottomSheetRef?: MatBottomSheetRef<T>;
	private data: unknown;

	constructor() {
		super();
	}

	override openDialog(component: ComponentType<T>, data?: unknown): void {
		this.dialogQueue.push({ componentType: component, data: data });
		this.openNextDialog();
	}

	private openNextDialog() {
		if (this.bottomSheetRef) return;

		const modalEntity = this.dialogQueue.shift();

		if (!modalEntity || !modalEntity.componentType) return;

		this.setDialogData(modalEntity.data);

		this.bottomSheetRef = this.bottomSheet.open(modalEntity.componentType, {
			disableClose: true,
		});
	}

	override closeDialog(): void {
		this.bottomSheetRef?.dismiss();
		this.bottomSheetRef = undefined;
		this.data = undefined;
		setTimeout(() => this.openNextDialog(), 1000);
	}

	override getDialogData(): unknown {
		return this.data;
	}

	override reloadData(data: unknown): void {
		this.setDialogData(data);
	}

	private setDialogData(data: unknown) {
		this.data = data;
	}
}
